import React from "react";
import Link from "next/link";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery, Hidden, CssBaseline } from "@material-ui/core";
// import withUnauth from "./withUnauth";
// import { ProtectRoute } from '../hoc/ProtectRoute';

const useStyles = makeStyles((theme) => ({
  mainMainContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    padding: "0 5%",
    backgroundImage: `url('/background.svg')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: "#000 !important",
    overflow: "auto",
    "@media only screen and (max-width: 768px)": {
      width: "100%",
    },
  },
  imgContainer: {
    marginTop: "1rem",
    width: "100%",
    "@media only screen and (max-width: 959px)": {
      textAlign: "center",
    },
  },
  logoParent: {
    width: "170px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  supergrid: {
    height: "auto",
    // margin: '10em 0',
    display: "flex",
    // alignItems: 'center',
    "@media only screen and (max-width: 768px)": {
      marginTop: "0",
      justifyContent: "center !important",
    },
  },
  welcome: {
    width: "50%",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#fff",
    padding: "30px 40px",
    borderRadius: "8px",
    width: "468px",
  },
  form: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media only screen and (max-width: 960px)": {
      marginTop: "1em",
      width: "100%",
    },
  },
}));

const Template = ({ children }) => {
  const classes = useStyles();

  const matches = useMediaQuery("(min-width:769px)");

  return (
    <div className={classes.mainMainContainer}>
      <div className={classes.imgContainer}>
        <Link href="/">
          <img
            className={classes.logoParent}
            src="/auth-logo.svg"
            alt="vasiti-logo"
          />
        </Link>
      </div>

      <div className={classes.supergrid}>
        <Hidden smDown>
          <div className={classes.welcome}>
            <h1
              className="font-medium"
              style={{ fontSize: "48px", color: "white", marginTop: "6rem" }}
            >
              Welcome to <br /> Vasiti Seller Center
            </h1>
          </div>
        </Hidden>

        <div className={classes.form}>
          <CssBaseline />
          <div className={classes.paper}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Template;
