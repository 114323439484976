import { useState } from "react";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import { useSnackbar } from "notistack";

export default function ResendVerification(props) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const resendVerification = async (data) => {
    if (success) return; // prevent button from after a successfull trigger

    const url = `${process.env.BACKEND_URL}/auth/send-token`;
    setLoading(true);

    // try {
    const payload = {
      email: props.email,
      pageFrom: "seller",
    };

    await axios
      .post(url, payload)
      .then((res) => {
        setLoading(false);
        setSuccess(true);

        enqueueSnackbar(res?.data?.success, {
          variant: "success",
        });
      })
      .catch((e) => {
        if (e?.response?.data?.errors?.resendVerification) {
          setLoading(true);
        }

        enqueueSnackbar(
          e?.response?.data?.errors.message ||
            "Sorry, we could not sent your verification link at the moment",
          {
            variant: "error",
          }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="text-center">
      <button
        className={`btn btn-xs font-medium capitalize px-3 rounded
        ${props.primary ? "btn-primary" : "btn-[#222222"} ${
          props.large ? "btn-sm" : "btn-xs"
        }`}
        onClick={() => resendVerification()}
      >
        {loading ? (
          <div className="flex items-center">
            <div>Sending. Please wait</div>

            <CircularProgress
              size="1.3em"
              color="inherit"
              thickness={8}
              className="ml-2"
            />
          </div>
        ) : success ? (
          <div className="flex items-center">
            <div>Verification link sent</div>
            <svg
              fill="currentColor"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
              className="bi bi-check2-all h-4 w-4 text-white ml-2"
            >
              <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z" />
              <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z" />
            </svg>
          </div>
        ) : (
          props.text || "Resend verification link"
        )}
      </button>
    </div>
  );
}
