import React, { useEffect, useState } from "react";
import Link from "next/link";
import Template from "../components/Template";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { CircularProgress, Button } from "@material-ui/core";
import ResendVerification from "../components/_partials/ResendVerification";
// import Alert from "@material-ui/lab/Alert";
import { useRouter } from "next/router";
import useAuth from "../context/authenticate";
import { useForm } from "react-hook-form";
// import crypto from "crypto-js";
import AlertWithBorder from "../components/_partials/AlertWithBorder";

const Login = () => {
  const router = useRouter();
  const { login } = useAuth();
  const [resendModal, setResendModal] = useState(false);

  const initialState = {
    email: "",
    password: "",
  };

  const [messages, setMessages] = useState({
    ...initialState,
    success: "",
    failure: "",
  });
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [redirectURL, setRedirectURL] = useState("");
  const [showResendActivation, setShowResendActivation] = useState(false);
  const [email, setEmail] = useState(null);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const body = {
      email: data.email ? data.email.trim() : "",
      password: data.password ? data.password.trim() : "",
      pageFrom: "seller",
    };

    setLoading(true);
    // save user email for reactivation purposes
    setEmail(data.email);

    try {
      const response = await login(body);
      setMessages({ ...messages, success: response.data.success });
      if (router.query.redirect) {
        router.push(redirectURL);
      } else {
        router.push("/dashboard");
      }
    } catch (e) {
      setLoading(false);

      if (e.response) {
        if (e.response.data.errors.resendVerification) {
          setShowResendActivation(true);
        }
        setMessages({
          ...messages,
          failure: e.response.data.errors.message,
        });
        // }
      }
    }
  };

  useEffect(() => {
    const params = new URL(document.location).searchParams;
    setRedirectURL(params.get("redirect") || "");
  }, []);

  return (
    <Template>
      {/* <h1 className="font-bold mb-3 text-lg text-center">Log In</h1> */}
      <header className="text-lg md:text-2xl font-black pb-2">Sign In</header>

      {(messages.failure || messages.success) && (
        <AlertWithBorder
          success={!!messages.success}
          message={messages.failure || messages.success}
          sendActivationCode={showResendActivation ? email : false}
        />
      )}

      <form
        className="w-full mt-4 text-sm"
        id="login-wholesaler"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="space-y-4">
          <div>
            <h4 className="text-1xss font-medium ">Email</h4>
            <input
              type="email"
              className="w-full block py-2 px-2 v-input"
              {...register("email", {
                required: true,
                pattern: /^\S+@\S+$/i,
              })}
            />
            {errors.email && errors.email.type === "required" && (
              <span role="alert" className="text-red-600 text-1xss italic">
                Email is required
              </span>
            )}
            {errors.email && errors.email.type === "pattern" && (
              <span role="alert" className="text-red-600 text-1xss italic">
                Email is invalid
              </span>
            )}
          </div>

          <div>
            <h4 className="text-1xss font-medium ">Password</h4>
            <div className="flex v-input p-0 focus-within:border-primary focus-within:outline-none">
              <input
                type={show ? "text" : "password"}
                className="w-full block py-2 px-2 bg-fa rounded border border-transparent focus:outline-none"
                id="password"
                {...register("password", {
                  required: true,
                })}
              />
              <Button
                aria-label="toggle password visibility"
                onClick={() => setShow(!show)}
                onMouseDown={handleMouseDownPassword}
              >
                {show ? <Visibility /> : <VisibilityOff />}
              </Button>
            </div>
            {errors.password && (
              <span className="text-red-600 text-1xss italic">
                Password is required
              </span>
            )}
          </div>
        </div>

        <button
          type="submit"
          className="btn btn-primary text-sm p-2 mt-6 mb-4 w-full"
        >
          {loading ? (
            <CircularProgress size="1em" style={{ color: "#fff" }} />
          ) : (
            "LOGIN"
          )}
        </button>

        <div>
            <a href="https://vasiti.com/signup?httpReferrer=seller_center" className="extraLink">
              {`Don't have an account?`} <b>Sign up</b>
            </a>

          <Link href="/forgot-password">
            <a className="extraLink">Forgot your password?</a>
          </Link>
        </div>
      </form>
    </Template>
  );
};

export default Login;
