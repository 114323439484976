import ResendVerification from "./ResendVerification";

export default function AlertWithBorder(props) {
  return (
    <div
      className={`alert ${
        props.success
          ? "alert-success border-success"
          : "alert-error border-error"
      } w-full text-sm space-x-2 border-2 rounded sm:px-8`}
    >
      <div className="flex flex-col">
        <div className="flex w-full">
          {props.success ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 mr-3"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 mr-3"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          )}

          <div>{props.message}</div>
        </div>

        {!!props.sendActivationCode && (
          <ResendVerification
            email={props.sendActivationCode}
            text="Resend Link"
          />
        )}
      </div>
    </div>
  );
}
